import { Button, Upload, UploadFile, UploadProps } from "antd";
import { UploadChangeParam } from "antd/es/upload";
import { useEffect, useState } from "react";
import FileIcon from "../../assets/icons/FileIcon";
import AXIOS from "../../helpers/api";
import { BASE_API } from "../../helpers/apiUrl";
import { toast } from "../../helpers/toast";
import { stringToArray } from "../../utils/array";
import { sliceString } from "../../utils/string";
import SearchIcon from "../../assets/icons/SearchIcon";
import CloseIcon from "../../assets/icons/CloseIcon";

type Props = {
  errorMessage?: string;
  form: any;
  name: any;
  disabled?: boolean;
  fileType?: boolean;
  accept?: string;
  onChange?: (url: string | null) => void;
} & UploadProps;

export default function FileUpload({
  errorMessage = "File upload fail",
  form,
  name,
  disabled,
  fileType,
  onChange,
  accept = ".png,.jpeg,.svg",
  ...rest
}: Props) {
  const [loading, setLoading] = useState(false);
  const [fileName, setFileName] = useState("Browse file");
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [uploaded, setUploaded] = useState(false);

  const handleUpload = async (file: any) => {
    const formData = new FormData();
    formData.append("photo", file);
    formData.append("name", file.name);

    setLoading(true);

    try {
      const response = await AXIOS.post(`${BASE_API.fileUpload}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      const url = response?.data?.photo;
      const fileNameArr = stringToArray(url, ".");

      if (response.status === 200) {
        setFileName(
          `${sliceString(url, 10, "...")}.${
            fileNameArr?.[fileNameArr?.length - 1]
          }`
        );

        setUploaded(true);

        onChange && onChange(url);

        // form.setFieldsValue({ [name]: url });
        setLoading(false);
      }
    } catch (error) {
      toast("error", errorMessage);
      setLoading(false);
    }

    return false;
  };

  // const handleChange = (info: UploadChangeParam<UploadFile<any>>) => {
  //   setFileList(info.fileList);

  //   if (info.file.status === "removed") {
  //     onChange && onChange(null);
  //     // form.setFieldsValue({ [name]: null });
  //     setFileName("");
  //   }
  // };

  // const handleChange = (info: UploadChangeParam<UploadFile<any>>) => {
  //   if (info.file.status === "removed") {
  //     return; // Prevent the removal action
  //   }

  //   setFileList(info.fileList);

  //   if (info.file.status === "done") {
  //     setFileList([]); // Clear fileList to remove cross icon
  //   }
  // };

  useEffect(() => {
    if (fileType) {
      const file = form.getFieldValue([name]);
      const fileNameArr = file ? stringToArray(file, ".") : "";

      setFileName(
        file
          ? `${sliceString(file, 15, "...")}.${
              fileNameArr?.[fileNameArr?.length - 1]
            }`
          : "Browse file"
      );
    }
  }, [form, name, fileType]);

  const handleRemoveFile = () => {
    setFileList([]);
    setFileName("Browse file");
    setUploaded(false); // Reset upload state
    onChange && onChange(null);
  };

  return (
    <Upload
      name="photo"
      beforeUpload={handleUpload}
      maxCount={1}
      accept={accept}
      className="custom-upload"
      showUploadList={false}
      // onChange={handleChange}
      fileList={fileList}
      disabled={disabled}
      style={{ width: "100%" }}
      {...rest}
    >
      {/* <Button
        className="flex justify-between items-center"
        size="large"
        disabled={disabled}
        style={{ width: "100%" }}
      >
        <div className="flex items-center gap-2 text-sm font-normal font-roboto text-[#8797ae]">
          <span className="text-accent font-medium text-base leading-7 relative">
            Choose File
          </span>
          <span className="border-r border-solid border-l-0 border-y-0 border-others-border h-4" />
          {loading ? "Uploading..." : fileName}
        </div>
        <FileIcon />
      </Button> */}

      <Button
        className="flex justify-between items-center relative group"
        size="large"
        disabled={disabled}
        style={{ width: "100%", position: "relative" }}
      >
        <div className="flex items-center gap-2 text-sm font-normal font-roboto text-[#8797ae]">
          <span className="text-accent font-medium text-base leading-7 relative">
            Choose File
          </span>
          <span className="border-r border-solid border-l-0 border-y-0 border-others-border h-4" />
          {loading ? "Uploading..." : fileName}
        </div>

        {uploaded ? (
          <span
            onClick={(e: any) => {
              e.stopPropagation();
              handleRemoveFile();
            }}
          >
            <CloseIcon />
          </span>
        ) : (
          <FileIcon />
        )}
      </Button>
    </Upload>
  );
}
